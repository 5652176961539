import * as styles from './index.module.scss';

import React from 'react';
import { useRouter } from 'next/router';

import { trackEvent } from '@/helpers/helpers_tracking';
import { SiteContext } from '@/contexts/site-context';
import { CATEGORY_SUBSETS } from '@/contexts/categories_subset';

const CategorySelect = () => {
  const [error, setError] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState({});
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [highlightedIndex, setHighlightedIndex] = React.useState(-1);
  const [, , isMobile] = React.useContext(SiteContext);

  const categories = CATEGORY_SUBSETS.filter((c) => !c.exclude_from_popular_categories);

  const router = useRouter();

  const redirectToCategory = ({ use_custom_question_flow, url_slug }) => {
    try {
      trackEvent({
        event_type: 'conversion',
        event_category: 'form',
        event_action: 'start'
      });
      console.debug('event tracked');
    } catch (e) {
      console.debug('event not tracked', e);
    }

    if (use_custom_question_flow) {
      router.push(`/cf/${url_slug}`);
    } else {
      router.push(`/flow/${url_slug}`);
    }
  };

  const handleSelect = (e) => {
    setError(false);
    redirectToCategory(e.target.value);
  };

  const validate = () => {
    if (!selectedCategory.url_slug) {
      setError(true);
    } else {
      redirectToCategory(selectedCategory);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleKeyDown = (e) => {
    if (isDropdownOpen && ['ArrowDown', 'ArrowUp', 'Enter'].includes(e.key)) {
      e.preventDefault();

      const currentIndex = categories.findIndex((category) => category.id === selectedCategory.id);

      switch (e.key) {
        case 'ArrowDown':
          const nextIndex = Math.min(currentIndex + 1, categories.length - 1);
          setHighlightedIndex(nextIndex);
          setSelectedCategory(categories[nextIndex]);
          break;
        case 'ArrowUp':
          const prevIndex = Math.max(currentIndex - 1, 0);
          setHighlightedIndex(prevIndex);
          setSelectedCategory(categories[prevIndex]);
          break;
        case 'Enter':
          closeDropdown();
          validate();
          break;
        default:
          break;
      }
    } else if (!isDropdownOpen && e.key === 'Enter') {
      toggleDropdown();
    }
  };

  return (
    <div className={styles['hero-category-picker-container']}>
      <div className={styles['sms-form-wrapper']}>
        <fieldset className={styles['panel-content']}>
          <legend>Choose a service category</legend>
          <div className={styles['error_msg']}>{error ? 'Please select a service category' : null}</div>
          <div className={styles['row']}>
            <div className={`${styles['input-container']} ${styles['category']} ${error ? `${styles['error']} error` : ''}`}>
              <div className={`${styles['custom-dropdown']} ${isDropdownOpen ? styles['open'] : ''}`}>
                <div className={styles['selected-value']} onClick={toggleDropdown} onKeyDown={handleKeyDown} role="button" tabIndex="0">
                  {selectedCategory.name || (isMobile ? 'Select service' : 'What service do you need?')}
                </div>
                {isDropdownOpen && (
                  <ul className={styles['dropdown-list']}>
                    {categories.map((category, index) => (
                      <li
                        key={`${category.id}-${category.name}`}
                        className={highlightedIndex === index ? styles['highlighted'] : ''}
                        onClick={() => {
                          handleSelect({
                            target: {
                              value: {
                                id: category.id,
                                name: category.name,
                                url_slug: category.url_slug,
                                use_custom_question_flow: category.use_custom_question_flow
                              }
                            }
                          });
                          closeDropdown();
                        }}
                        onKeyDown={handleKeyDown}
                        role="button"
                        tabIndex="0"
                      >
                        {category.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className={`desktop ${styles['actions']}`}>
              <button className={`${styles['search']} button`} onClick={validate}>
                Find Pros
              </button>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default CategorySelect;
