import * as styles from './index.module.scss';

import Image from 'next/image';

import CategoryLinks from '@/components/CategoryLinks';
import CategorySelect from '@/components/VikingFlow/CategorySelect';
import DailyCount from '@/components/DailyCount';

import { QueryParamElement } from '@/components/QueryParamElement';

const defaultHeadline = 'Get Home Repair Help Fast!';

const Hero = ({ daily_count = 0 }) => (
  <div className={styles['hero-container']}>
    <div className={`${styles['container']} container`}>
      <QueryParamElement element="h1" queryParam="h1" fallbackText={defaultHeadline} />
      <CategorySelect />
      {daily_count > 0 && <DailyCount count={daily_count} />}
      <CategoryLinks />
      <Image
        className={styles['hero']}
        src="https://s3.amazonaws.com/callapro.com/hero_bgImg.png"
        priority
        fetchPriority="high"
        loading="eager"
        aria-hidden="true"
        alt="hero image"
        quality={80}
        placeholder="blur"
        blurDataURL="iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/C/HgAGgwJ/lK3Q6wAAAABJRU5ErkJggg=="
        height={857}
        width={1728}
      />
    </div>
  </div>
);

export default Hero;
