import * as styles from './index.module.scss';

import Link from 'next/link';

import { trackEvent } from '@/helpers/helpers_tracking';
import CategoryIcon from '@/components/CategoryIcon';
import { CATEGORY_SUBSETS } from '@/contexts/categories_subset';

const MAX_LINKS = 6;

const CategoryLinks = () => {
  const handleClick = () => {
    try {
      trackEvent({
        event_type: 'conversion',
        event_category: 'form',
        event_action: 'start'
      });
      console.debug('event tracked');
    } catch (e) {
      console.debug('event not tracked', e);
    }
  };

  return (
    <div className={styles['category-links-wrapper']}>
      <div className={styles['category-links-container']}>
        {CATEGORY_SUBSETS.filter((category) => !category.exclude_from_popular_categories)
          .slice(0, MAX_LINKS)
          .map((category) => (
            <Link
              href={category.use_custom_question_flow ? `/cf/${category.url_slug}` : `/flow/${category.url_slug}`}
              className={styles['category-link']}
              key={category.url_slug}
              onClick={handleClick}
            >
              <div className={styles['icon-container']}>
                <div className={styles['icon-wrapper']}>
                  <CategoryIcon name={category.name_adjective.replace(/\s/g, '')} />
                </div>
              </div>
              <span>{category.name_adjective}</span>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default CategoryLinks;
