// import { MdElectricalServices as ElectriciansIcon } from 'react-icons/md'
// import { GiAnt as ExterminatorsIcon } from 'react-icons/gi'
// import { GiHouseKeys as LocksmithsIcon } from 'react-icons/gi'
// import { MdHvac as HeatingContractorsIcon } from 'react-icons/md'
// import { MdRoofing as RoofersIcon } from 'react-icons/md'
// import { MdPlumbing as PlumbersIcon } from 'react-icons/md'

import AirConditioningIcon from 'svgs/category/air-conditioning.svg';
import ApplianceRepairIcon from 'svgs/category/appliance-repair.svg';
import AttorneyIcon from 'svgs/category/attorney.svg';
import AutoGlassIcon from 'svgs/category/auto-glass.svg';
import AutoInsuranceSr22Icon from 'svgs/category/auto-insurance-sr22.svg';
import AutoInsuranceIcon from 'svgs/category/auto-insurance.svg';
import AutoRepairIcon from 'svgs/category/car-repair.svg';
import BankruptcyLawyersIcon from 'svgs/category/bankruptcy-lawyers.svg';
import BathroomRemodelingIcon from 'svgs/category/bathroom-remodeling.svg';
import CarAccidentIcon from 'svgs/category/car-accident.svg';
import ChiropractorsIcon from 'svgs/category/spine.svg';
import CriminalLawyersIcon from 'svgs/category/criminal-lawyers.svg';
import CollectionsLawyersIcon from 'svgs/category/bail-payment.svg';
import DentistsIcon from 'svgs/category/dentists.svg';
import DenturesIcon from 'svgs/category/dentures.svg';
import DermatologistIcon from 'svgs/category/dermatologist.svg';
import DivorceLawyersIcon from 'svgs/category/divorce-decree.svg';
import DrugAlcoholAddictionTreatmentIcon from 'svgs/category/drug-alcohol-addiction-treatment.svg';
import ElectriciansIcon from 'svgs/category/redesign/electrical.svg';
import ExterminatorsIcon from 'svgs/category/redesign/pest.svg';
import FamilyLawyersIcon from 'svgs/category/family-lawyers.svg';
import GavelIcon from 'svgs/category/gavel.svg';
import GeneralHouseIcon from 'svgs/category/renovation.svg';
import HealthInsuranceIcon from 'svgs/category/health-insurance.svg';
import HeatingContractorsIcon from 'svgs/category/redesign/hvac.svg';
import HomeownersInsuranceIcon from 'svgs/category/home-insurance.svg';
import InsuranceIcon from 'svgs/category/insurance.svg';
import LandscapingIcon from 'svgs/category/landscaping.svg';
import LegalIcon from 'svgs/category/scales-of-justice.svg';
import LifeInsuranceIcon from 'svgs/category/life-insurance.svg';
import LocksmithsIcon from 'svgs/category/redesign/locksmith.svg';
import MedicareInsuranceIcon from 'svgs/category/medicare-insurance.svg';
import MoldRemediationIcon from 'svgs/category/mold-remediation.svg';
import OphthalmologistIcon from 'svgs/category/eye-test.svg';
import PaintersIcon from 'svgs/category/painters.svg';
import PersonalInjuryLawyersIcon from 'svgs/category/personal-injury-lawyers.svg';
import PhysicalTherapistsIcon from 'svgs/category/physical-therapists.svg';
import PodiatristIcon from 'svgs/category/footprint.svg';
import PlumbersIcon from 'svgs/category/redesign/plumbing.svg';
import RoofersIcon from 'svgs/category/redesign/roofing.svg';
import SocialSecurityDisabilityLawyersIcon from 'svgs/category/social-security-disability-lawyers.svg';
import TalcumPowderLawyersIcon from 'svgs/category/talcum-powder-lawyers.svg';
import TowingIcon from 'svgs/category/towing-car.svg';
import VeterinariansIcon from 'svgs/category/veterinarians.svg';
import WaterFireDamageRestorationIcon from 'svgs/category/water-fire-damage-restoration.svg';
import WildlifeControlIcon from 'svgs/category/wildlife-control.svg';
import WindowsInstallersIcon from 'svgs/category/windows-installers.svg';
import WorkersCompensationLawyersIcon from 'svgs/category/workers-compensation-lawyers.svg';

const CategoryIcon = ({ name = '' }) => {
  const getIcon = (key) => {
    switch (key) {
      case 'AnimalCare':
        return <VeterinariansIcon />;
      case 'AnimalInsurance':
        return <VeterinariansIcon />;
      case 'Appliance Repair':
      case 'ApplianceRepair':
        return <ApplianceRepairIcon />;
      case 'Auto':
        return <AutoRepairIcon />;
      case 'AutoGlass':
        return <AutoGlassIcon />;
      case 'CarAccident':
        return <CarAccidentIcon />;
      case 'ClassAction':
        return <GavelIcon />;
      case 'CorporateLaw':
        return <AttorneyIcon />;
      case 'CriminalDefense':
        return <CriminalLawyersIcon />;
      case 'Dental':
        return <DentistsIcon />;
      case 'DivorceLaw':
        return <DivorceLawyersIcon />;
      case 'Electricians':
        return <ElectriciansIcon />;
      case 'Electrical':
        return <ElectriciansIcon />;
      case 'EmploymentLaw':
        return <LegalIcon />;
      case 'FamilyLaw':
        return <FamilyLawyersIcon />;
      case 'FinancialLaw':
        return <BankruptcyLawyersIcon />;
      case 'GeneralInsurance':
        return <LifeInsuranceIcon />;
      case 'HVAC':
        return <HeatingContractorsIcon />;
      case 'HealthPolicy':
        return <HealthInsuranceIcon />;
      case 'HealthSpecialists':
        return <DrugAlcoholAddictionTreatmentIcon />;
      case 'HomeRenters':
        return <InsuranceIcon />;
      case 'Investment':
        return <LegalIcon />;
      case 'Landscaping':
        return <LandscapingIcon />;
      case 'LegalAid':
        return <LegalIcon />;
      case 'Locksmith':
        return <LocksmithsIcon />;
      case 'MedicalInjuryLawyers':
        return <PersonalInjuryLawyersIcon />;
      case 'MedicalMalpractice':
        return <PersonalInjuryLawyersIcon />;
      case 'MoldRemediation':
        return <MoldRemediationIcon />;
      case 'PestControl':
        return <ExterminatorsIcon />;
      case 'Plumbing':
        return <PlumbersIcon />;
      case 'RealEstateLaw':
        return <LegalIcon />;
      case 'Renovation':
        return <GeneralHouseIcon />;
      case 'Retirement':
        return <MedicareInsuranceIcon />;
      case 'Roofing':
        return <RoofersIcon />;
      case 'Towing':
        return <TowingIcon />;
      case 'Veterinary':
        return <VeterinariansIcon />;
      case 'Water&FireDamage':
        return <WaterFireDamageRestorationIcon />;
      case 'WaterFireRestoration':
        return <WaterFireDamageRestorationIcon />;
      case 'addiction-treatment':
        return <DrugAlcoholAddictionTreatmentIcon />;
      case 'air-conditioning-contractors':
        return <AirConditioningIcon />;
      case 'auto-body-shops':
        return <CarAccidentIcon />;
      case 'auto-insurance-sr22':
        return <AutoInsuranceSr22Icon />;
      case 'auto-insurance':
        return <AutoInsuranceIcon />;
      case 'bankruptcy-lawyers':
        return <BankruptcyLawyersIcon />;
      case 'bathroom-remodeling-contractors':
        return <BathroomRemodelingIcon />;
      case 'chiropractors':
        return <ChiropractorsIcon />;
      case 'collections-lawyers':
        return <CollectionsLawyersIcon />;
      case 'dentures':
        return <DenturesIcon />;
      case 'dermatologists':
        return <DermatologistIcon />;
      case 'dui-lawyers':
        return <CarAccidentIcon />;
      case 'exterminators':
        return <ExterminatorsIcon />;
      case 'health-insurance':
        return <HealthInsuranceIcon />;
      case 'heating-contractors':
        return <HeatingContractorsIcon />;
      case 'homeowners-insurance':
        return <HomeownersInsuranceIcon />;
      case 'kitchen-remodeling-contractors':
        return <GeneralHouseIcon />;
      case 'lawyers':
        return <LegalIcon />;
      case 'life-insurance':
        return <LifeInsuranceIcon />;
      case 'locksmiths':
        return <LocksmithsIcon />;
      case 'medicare-supplemental-insurance':
        return <MedicareInsuranceIcon />;
      case 'mold-remediation':
        return <MoldRemediationIcon />;
      case 'medicare':
        return <MedicareInsuranceIcon />;
      case 'ophthalmologists':
        return <OphthalmologistIcon />;
      case 'painters':
        return <PaintersIcon />;
      case 'personal-injury-lawyers':
        return <PersonalInjuryLawyersIcon />;
      case 'pest-control':
        return <ExterminatorsIcon />;
      case 'physical-therapists':
        return <PhysicalTherapistsIcon />;
      case 'plumbers':
        return <PlumbersIcon />;
      case 'podiatrists':
        return <PodiatristIcon />;
      case 'renters-insurance':
        return <InsuranceIcon />;
      case 'roofers':
        return <RoofersIcon />;
      case 'disability-lawyers':
        return <SocialSecurityDisabilityLawyersIcon />;
      case 'talcum-powder':
        return <TalcumPowderLawyersIcon />;
      case 'towing-service':
        return <TowingIcon />;
      case 'veterinarians':
        return <VeterinariansIcon />;
      case 'water-fire-damage-restoration':
        return <WaterFireDamageRestorationIcon />;
      case 'wildlife':
        return <WildlifeControlIcon />;
      case 'window-installers':
        return <WindowsInstallersIcon />;
      case 'workers-compensation-lawyers':
        return <WorkersCompensationLawyersIcon />;
      default:
        return <GeneralHouseIcon />;
    }
  };

  return getIcon(name);
};

export default CategoryIcon;
