import { FC, useEffect, useState } from 'react';

import { validUserInput } from '@/helpers/helpers';

type RenderedText = {
  text: string;
  safeToInject?: boolean;
};

interface QueryParamElementProps {
  /** the element to be created */
  element: keyof JSX.IntrinsicElements;
  queryParam: string;
  fallbackText: string;
  injectFallback?: boolean;
  className?: string;
}

/**
 * Safely creates an element with content parsed from query parameters.
 * If the query parameter is not found or is considered invalid, the fallback will be used.
 *
 * _If `injectFallback` is set to `true`, the returned `Element` will inject the fallback using `dangerouslySetInnerHTML`._
 */
export const QueryParamElement: FC<QueryParamElementProps> = ({ element, queryParam, fallbackText, className, injectFallback }) => {
  const [content, setContent] = useState<RenderedText>({ text: '', safeToInject: false });

  useEffect(() => {
    const queryValue = new URLSearchParams(window.location.search).get(queryParam);

    if (queryValue && typeof queryValue === 'string' && validUserInput(queryValue)) {
      setContent({ text: queryValue, safeToInject: false });
    } else {
      setContent({ text: fallbackText, safeToInject: injectFallback });
    }
  }, [queryParam, fallbackText, injectFallback]);

  const Element = element;

  return content.safeToInject ? <Element dangerouslySetInnerHTML={{ __html: content.text }} /> : <Element className={className}>{content.text}</Element>;
};
