import * as styles from './index.module.scss';

import Image from 'next/image';

const DailyCount = (props) => {
  return props.count ? (
    <div className={styles['daily-count']}>
      <div className={styles['icons-container']}>
        <Image
          src="/images/daily-count-01.png"
          width={77}
          height={76}
          alt="daily count"
          quality={80}
          placeholder="blur"
          blurDataURL="iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/C/HgAGgwJ/lK3Q6wAAAABJRU5ErkJggg=="
        />
        <Image
          src="/images/daily-count-02.png"
          width={85}
          height={84}
          alt="daily count"
          quality={80}
          placeholder="blur"
          blurDataURL="iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/C/HgAGgwJ/lK3Q6wAAAABJRU5ErkJggg=="
        />
      </div>
      <span>
        Used by <strong>{props.count}</strong> customers in the last 24 hours.
      </span>
    </div>
  ) : null;
};

export default DailyCount;
